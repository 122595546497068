import React, { useState, useEffect } from 'react';
import './Material.css';
import { Footer } from '../Footer/Footer';
import legales1 from '../../Assets/BANNERBACK.png';
import legales2 from '../../Assets/BANNERBACK2.png';

import legalesMob1 from '../../Assets/mob1.png';
import legalesMob2 from '../../Assets/mob2.png';

import Guia from '../../Assets/Read/guia.docx';
import Byc from '../../Assets/Read/Byc.docx';

export const Material = () => {
  const [isMobile, setIsMobile] = useState(false);

  // Efecto para escuchar cambios en el tamaño de la pantalla
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1024);
    };

    // Verifica al cargar la página
    handleResize();

    // Agrega un event listener para cambiar cuando el tamaño cambie
    window.addEventListener('resize', handleResize);

    // Limpia el event listener cuando el componente se desmonta
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='content-legales'>
      <div className='content-legales-titles'>
        <h2 className='legales-titles'>
          Legales
        </h2>
      </div>


      <div className='content-banners' >
        <a href={Byc} download>
          <div className='content-banners-grafics'>
            <div className='banners-grafics'>
              <img
                src={isMobile ? legalesMob1 : legales1}
                alt="premio peabody"
              />
            </div>
            <div className='banners-grafics-text'>
              <div>
              {/* <a href="" > */}
                <span className='span1'>BASES Y CONDICIONES</span>
                {/* </a>  */}
                <p>Descargá las consideraciones y los aspectos claves.</p>
              </div>
            </div>
          </div>
        </a>


      <a href={Guia} download>
          <div className='content-banners-grafics'>
            <div className='banners-grafics-text'>
              <div>
               <span className='span2'>GUÍA DE PARTICIPACIÓN</span> 
                <p>Enterate cómo ser parte del Premio Peabody 2024.</p>
              </div>
            </div>
            <div className='banners-grafics'>
              <img
                src={isMobile ? legalesMob2 : legales2}
                alt="premio peabody"
              />
            </div>
          </div>
        </a>
      </div>

      <Footer />
    </div>
  );
};
