import React, { useEffect, useRef } from 'react';

const LocationAutocomplete = ({ value, onChange, placeholder, disabled }) => {
    const inputRef = useRef(null);
    //console.log(value)
    useEffect(() => {
        // Asegúrate de que la API de Google Maps esté cargada
        if (!window.google) {
            console.error('Google Maps API no está cargada.');
            return;
        }

        if (disabled) return; // No inicializar el autocomplete si el campo está deshabilitado

        const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current, {
            componentRestrictions: { country: 'AR' }, // Restringir a Argentina
            fields: ['formatted_address', 'geometry'], // Los campos que quieres recibir
            types: ['geocode'], // Tipos de resultados a mostrar
        });

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (!place.geometry) {
                console.error("No se encontraron detalles sobre el lugar seleccionado.");
                return;
            }
            
            // Extraer la dirección y las coordenadas del lugar seleccionado
            const description = place.formatted_address;
            const lat = place.geometry.location.lat();
            const lng = place.geometry.location.lng();
            //console.log("Coordenadas: ", { lat, lng });
            
            // Separar la dirección en partes
            const addressParts = description.split(',');
            const domicilio = addressParts[0]?.trim() || '';
            const ciudadProvincia = addressParts[1]?.trim() || '';
            const pais = addressParts[2]?.trim() || '';
            
            // Separar ciudad y provincia si el formato es esperado
            let ciudad = '';
            let provincia = '';
            if (ciudadProvincia.includes(' Provincia de ')) {
                [ciudad, provincia] = ciudadProvincia.split(' Provincia de ');
            } else {
                ciudad = ciudadProvincia;
            }

           // console.log({ domicilio, ciudad, provincia, pais });
            onChange({ domicilio, ciudad, provincia, pais });
        });
    }, [onChange, disabled]);

    return (
        <div className="form-group">
            <input
                ref={inputRef}
                type="text"
                value={value}
                onChange={(e) => !disabled && onChange(e.target.value)}
                placeholder={placeholder || "Escribe una ubicación"}
                className="form-control"
                disabled={disabled}
                required
            />
        </div>
    );
};

export default LocationAutocomplete;
