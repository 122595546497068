export const sendEventBlue = async (userData) => {
// API Key en Base64 (ya está codificada)
    const apiKey = process.env.REACT_APP_EMBLU_API_KEY;
  //  console.log(process.env.REACT_APP_EMBLU_API_KEY)
// Configura los datos del evento
    const evento = {
      eventName: 'premio-peabody', // Reemplaza con el nombre del evento predefinido en Emblu
      email: userData.email,
      attributes:  {
        Nombre: userData.nombre,
        Apellido: userData.apellido,
        Cuit_Dni:userData.dni,
        Ciudad:  userData.ciudad,
        Domicilio:userData.domicilio,
        Provincia:  userData.provincia,
        FechaNacim: userData.fechaNacimiento,
        Pseudonimo: userData.pseudonimo,
        Telefono:   userData.telefono,
        archivoParticipante:  userData.archivoParticipante || userData.pseudonimo,
        archivoProyecto:  userData.archivoProyecto,
        archivoFirmaProyecto: userData.archivoFirma,
        email_secun: userData.email
      }
    };
 //console.log(evento);
    try {
        const response = await fetch('https://track.embluemail.com/contacts/event', {
            method: 'POST',
            headers: {
                  'Authorization': `Basic ${apiKey}`,
                  'Content-Type': 'application/json'
                },
            body: JSON.stringify(evento)
          });
  
      if (response.ok) {
        //console.log('Evento enviado con éxito');
        return { success: true, message: 'Evento enviado con éxito' };
      } else {
        console.error('Error al enviar el evento');
        return { success: false, message: 'Error al enviar el evento' };
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
      return { success: false, message: `Error en la solicitud: ${error.message}` };
    }
  };

  export const sendBulkEventBlue = async (userData) => {
    const apiKey = process.env.REACT_APP_EMBLU_API_KEY; 
// console.log(userData)

  const evento = {
    eventName: 'premio-peabody', // Reemplaza con el nombre del evento predefinido en Emblu
    email: userData.email,
    attributes:  {
      Nombre: userData.nombre,
      Apellido: userData.apellido,
      Cuit_Dni:userData.dni,
      Ciudad:  userData.ciudad,
      Domicilio:userData.domicilio,
      Provincia:  userData.provincia,
      FechaNacim: userData.fechaNacimiento,
      Pseudonimo: userData.pseudonimo || '-',
      Telefono:   userData.telefono,
      archivoParticipante:  userData.archivoParticipante || userData.pseudonimo || '-',
      archivoProyecto:  userData.archivoProyecto || '-',
      archivoFirmaProyecto: userData.archivoFirma || '-',
      email_secun: userData.email
    }
  };
    try {
      const response = await fetch('https://track.embluemail.com/contacts/event', {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${apiKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(evento)
      });
  
      if (response.ok) {
        console.log('Evento enviado con éxito');
        return { success: true, message: 'Evento enviado con éxito' };
      } else {
        console.error('Error al enviar el evento');
        return { success: false, message: 'Error al enviar el evento' };
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
      return { success: false, message: `Error en la solicitud: ${error.message}` };
    }
  };


export const sendInfoEmblu = async (userData) => {
  const apiKey = process.env.REACT_APP_EMBLU_API_KEY; 
  console.log(userData)
  const evento = {
    eventName: 'busca_info', // Reemplaza con el nombre del evento predefinido en Emblu
    email: userData.Email,
    attributes:  {
      Nombre: userData.Nombre,
      SeconEmail: userData.Email,
    }
  };
    try {
      const response = await fetch('https://track.embluemail.com/contacts/event', {
        method: 'POST',
        headers: {
          'Authorization': `Basic ${apiKey}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(evento)
      });
  
      if (response.ok) {
        console.log('Evento enviado con éxito');
        return { success: true, message: 'Evento enviado con éxito' };
      } else {
        console.error('Error al enviar el evento');
        return { success: false, message: 'Error al enviar el evento' };
      }
    } catch (error) {
      console.error('Error en la solicitud:', error);
      return { success: false, message: `Error en la solicitud: ${error.message}` };
    }
  };