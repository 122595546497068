import React from 'react'
import   { CarrouselSlide }  from './CarrouselSlide/CarrouselSlide'
import { SectionSlide } from './SectionSlide/SectionSlide'
import { Footer } from '../Footer/Footer'
import { Loader } from '../Loader/Loader'

export const Home = () => {
  return (
    <>    
          <CarrouselSlide/> 
          <SectionSlide/>
          <Footer/>
    </>
  )
}
