import React, { useRef } from 'react';
import foto1 from '../../../Assets/banner/ban1.png'
import foto2 from '../../../Assets/banner/ban2.png'
import foto3 from '../../../Assets/banner/ban3.png'
import foto4 from '../../../Assets/banner/ban4.png'
import webfoto1 from '../../../Assets/mobile/web1.png'
import webfoto2 from '../../../Assets/mobile/web2.png'
import webfoto3 from '../../../Assets/mobile/web3.png'
import webfoto4 from '../../../Assets/mobile/web4.png'
//import foto4 from '../../../Assets/peabodyPremiosParte4.png'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './CarrouselSlide.css'


export const CarrouselSlide = () => {
    const images = [foto1, foto2, foto3,foto4];
    const imagesMobile = [webfoto1,webfoto2,webfoto3,webfoto4]
    const sliderRef = useRef(null); // Referencia al slider para controlar la navegación
    
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: false, // Desactiva las flechas predeterminadas
    };

    const goToPrevious = () => {
        sliderRef.current.slickPrev(); // Navega a la diapositiva anterior
    };

    const goToNext = () => {
        sliderRef.current.slickNext(); // Navega a la siguiente diapositiva
    };

    return (
        
    <>
        <div className="carrousel">
            <Slider {...settings} ref={sliderRef}>
                {images.map((image, index) => (
                    <div key={index}>
                        <img src={image} alt={`Producto innovador Peabody diseñado para los premios de diseño`} className="slide-image" />
                    </div>
                ))}
            </Slider>
            {/* <button className="carrousel-btn carrousel-btn-prev" onClick={goToPrevious}>←</button>
            <button className="carrousel-btn carrousel-btn-next" onClick={goToNext}>→</button> */}
        </div>

        <div className="carrousel-mobile">
            <Slider {...settings} ref={sliderRef}>
                {imagesMobile.map((image, index) => (
                    <div key={index}>
                        <img src={image} alt={`Producto innovador Peabody diseñado para los premios de diseño`} className="slide-image" />
                    </div>
                ))}
            </Slider>
            {/* <button className="carrousel-btn carrousel-btn-prev" onClick={goToPrevious}>←</button>
            <button className="carrousel-btn carrousel-btn-next" onClick={goToNext}>→</button> */}
        </div>
    </>
    );
};