import React from 'react'

export const Fechas = () => {
  return (
    <div>
      <div className="premios-section">
        <p>
           <strong>
               Presentación de proyectos del <span style={{color:'#007bff', fontSize:'20px'}}> 14 DE OCTUBRE DE 2024 </span>  AL  <span style={{color:'#007bff', fontSize:'20px'}}>  10 DE MARZO DE 2025 </span>
            </strong>        
        </p>
      </div>
     
    </div>
  );
};
