import './App.css';
import { Home } from './Components/Home/Home';
import { Material } from './Components/Material/Material';
import { Navbar } from './Components/Navbar/Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Subscription } from './Components/Subscription/Subscription';
import { LoadScript } from '@react-google-maps/api';
import { useState } from 'react';
import { Loader } from './Components/Loader/Loader';

function App() {
  const [isGoogleApiLoaded, setGoogleApiLoaded] = useState(false);

  return (
    <Router>
      <Navbar />
      <LoadScript
        googleMapsApiKey={process.env.REACT_APP_TOKEN_PLACES}
        libraries={['places']}
        onLoad={() => setGoogleApiLoaded(true)}
      >
        <Routes>
          <Route path="/" element={<Home/>} />
          <Route path="/material" element={<Material/>} />
          <Route
            path="/subscription"
            element={isGoogleApiLoaded ? <Subscription/> : <Loader/>}
          />
        </Routes>
      </LoadScript>
    </Router>
  );
}

export default App;
