import React from 'react'

export const Jurado = () => {
  return (
    <div>

    <div className="premios-section">
        <div>
            <p>
            Las ideas participantes serán evaluadas por un jurado multidisciplinario compuesto
            por cinco integrantes de reconocido prestigio profesional y académico. Composición
            del jurado y proceso de evaluación. El jurado estará compuesto por profesionales de
            diversas áreas relevantes, no limitándose solo a diseñadores industriales, sino
            incluyendo ingenieros, arquitectos y otros expertos que aporten su conocimiento y
            experiencia. Además, se incorporarán instancias de corrección de proyecto con
            jurados o referentes especializados. Esto permitirá a los estudiantes recibir feedback
            constructivo y a la organización del premio tener una visión anticipada de las
            temáticas y el nivel de desarrollo de los proyectos. Desde principios de diciembre a
            febrero se podrá solicitar una única tutoría por grupo sobre trabajos avanzados, no
            ideas iniciales. Las mismas serán en modalidad virtual.
            </p>
            <ul style={{listStyle:'none'}}> 
              El jurado estará conformado por:
                  <li>  <span style={{color:'#007bff', fontSize:'20px'}}> D.I </span> Victoria Rique</li>
                  <li>  <span style={{color:'#007bff', fontSize:'20px'}}> D.I </span> Federico Churba</li>
                  <li>  <span style={{color:'#007bff', fontSize:'20px'}}> D.I </span> Camila Offenhenden</li>
                  <li>  <span style={{color:'#007bff', fontSize:'20px'}}> D.IN </span>Gabriela Lopez</li>
                  <li>  <span style={{color:'#007bff', fontSize:'20px'}}> D.I </span> Heidi Jalkh</li>
                  <li>  <span style={{color:'#007bff', fontSize:'20px'}}> ING </span>Guillermo Duodero</li>
              </ul>
        </div>
    </div>

  
</div>
  )
}
