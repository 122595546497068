import React from 'react'

export const Participacion = () => {
  return (
    <div>
       <div className="premios-section">
        <div>
            <h4>Condiciones para la inscripción</h4>
        </div>
        <div>
            <p> 
            <strong className='stong-blue'>1.</strong> Completar todos los datos personales requeridos. <br /><br />
            <strong className='stong-blue'>2.</strong> Subir en el sitio web la constancia de Alumno regular, o Certificado de Materias aprobadas,
            emitido por la institución educativa correspondiente.<br /><br />
            <strong className='stong-blue'>3.</strong> Identificar si participa en <strong className='stong-black'> “Grupo”</strong> o <strong className='stong-black'>“Individual”</strong> .<br /><br />
            <strong className='stong-blue'>4.</strong> Si participa en “Grupo” agregar todos los datos requeridos de cada participante, junto con la
            constancia de Alumno regular, o Certificado de Materias aprobadas de cada uno.<br /><br />
            <strong className='stong-blue'>5.</strong> Cargar en el sitio web la Idea con la que se concursará, <strong className='stong-black'>en formato PDF, de una extensión
            máxima de 5 páginas y un peso no mayor a 10 MB. </strong><br /><br />
            <strong className='stong-blue'>6.</strong> Leer detenidamente las Bases y Condiciones. En caso de aceptarlas presione el ícono de Aceptar
            Bases y Condiciones.<br /><br />
            <strong  className='stong-blue'>7.</strong> Una vez finalizado el proceso de Inscripción se le suministrará un código de participación que lo
            identificará como participante en todas las etapas previstas en las Bases y Condiciones.
            <br /><br />
            <strong  className='stong-blue'>8.</strong> Dentro de las 48 horas recibirá en su casilla de mail un correo de
            inscripcion@premiopeabody.com.ar con el código del proyecto como asunto, donde se solicitará
            responderlo adjuntando la Carta de Autoría, la cual deberá descargarla de la sección “Materiales” de
            la página web www.premiopeabody.com.ar. La misma deberá estar firmada de puño y letra por el/la
            Participante (en caso de que concurse individualmente) o por el/la representante (en caso de que
            participe de manera grupal).<br /><br />
              </p>
        </div>
        </div>

        <div className="premios-section">
            <div>
                <h4>Presentación del material</h4>
            </div>
            <div>
                <p>Los/as Participantes deberán subir al sitio web <strong className='stong-black'> www.premiopeabody.com.ar </strong></p>

                  <ul>
                    <li><strong> Presentación en tamaño A3 </strong>, con un mínimo de 2 y un máximo de 5 láminas.</li>
                    <li>Deberá contener una <strong> memoria Descriptiva </strong>de la Idea/Propuesta explicando su base conceptual,
                    con precisiones sobre situación de uso y tecnología implicada en su funcionamiento.</li>
                    <li>La presentación debe contener imágenes, renders, infografías, dimensiones, y todo aquel material
                    gráfico que se considere pertinente para explicar la idea.</li>
                    <li>Todo el material debe estar guardado en un <strong> archivo PDF que esté identificado con un pseudónimo
                    del proyecto </strong> </li>
                  </ul>
                  <style>
                    {`
                      ul li::marker {
                        color: #007bff;
                      }
                    `}
                  </style>
            </div>
        </div>

      <div className="premios-section">
          <div>
              <h4>Participantes</h4>
          </div>
          <div>
              <p>
              Buscamos la participación de <strong>estudiantes de diversas disciplinas</strong> para enriquecer el
              concurso con una gama amplia de conocimientos. Si bien el eje central sigue siendo
              el diseño argentino,<strong> alentamos la inclusión de perspectivas de otras áreas en torno al
              diseño, como ingeniería, arquitectura, y desarrollo tecnológico.</strong> Esto asegura una
              evaluación integral y multidimensional de las propuestas. Podrá participar toda
              persona <strong>mayor de 18 años, residente en la República Argentina, que acredite ser
              estudiante de la carrera de grado de diseño, ingeniería, arquitectura y desarrollo
              tecnológico,</strong> tanto de universidades públicas como privadas, que cumpla con todos
              los requisitos previstos en las presentes bases. Podrán inscribirse de manera
              <strong>individual o grupal</strong> (en equipos de hasta 5 integrantes). En el caso de que se trate de
              un equipo, todos los integrantes deberán comprobar su <strong>condición de alumno regular.</strong> 
                </p>
          </div>
      </div>

      
    
  </div>
  
  )
}
