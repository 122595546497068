import React, { useState } from 'react';

const EmailForm = ({ email, setEmail }) => {
    const [error, setError] = useState('');

    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    const handleChange = (e) => {
        const inputEmail = e.target.value;
        setEmail(inputEmail);

        if (validateEmail(inputEmail)) {
            setError(''); // Email is valid
        } else {
            setError('Por favor, ingresa un correo electrónico válido.');
        }
    };

    return (
        <div>
            <input
                type="email"
                name="email"
                placeholder="Email"
                value={email}
                onChange={handleChange}
                required
            />
            {error && <p style={{ color: 'red' }}>{error}</p>}
        </div>
    );
};

export default EmailForm;
