import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { About, Jurado, Faqs, Fechas, Participacion, Premios } from '../MicroComponents'
import './SectionSlide.css';
import { setOpenSection, clearOpenSection } from '../../../store/sectionSlice';
import { useNavigate } from 'react-router-dom';


export const SectionSlide = () => {
  const dispatch = useDispatch();
  const openSection = useSelector((state) => state.section.openSection);
  const navigate = useNavigate(); // Inicializa el hook navigate

  
 

  useEffect(() => {
    // Despacha la acción para abrir la sección cuando cambia el estado
    if (openSection) {
      document.querySelector(`#${openSection}`)?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [openSection]);

  const toggleSection = (section) => {
    dispatch(setOpenSection(openSection === section ? null : section));
    //console.log(section)
  };

  const handleNavigation = () => {
    navigate('/subscription'); // Navega a la ruta /subscription
  };

  return (
    <div className="section-slide">
     
     <div className='container-subtitulo'>
        <h2 className='container-subtitulo-texto'>
                El Premio Peabody alienta a las nuevas generaciones a 
                <strong> explorar sobre nuestra cultura y nuevos escenarios de la vida cotidiana </strong>, 
                con el objetivo de proponer ideas que jamás hayan sido pensadas y conceptos que aún no han sido ideados.
        </h2>
        <button className='button-main-section'onClick={handleNavigation}>INSCRIBITE</button>
        
     </div>

      <div className="sections">
    
     
 {/* //-------------------------------------------------------------------------------------------------------        */}
        <div
            id="participacion"
            className={`section ${openSection === 'participacion' ? 'open' : ''}`}
            onClick={() => toggleSection('participacion')}
          >
            <div className={`section-header ${openSection === 'participacion' ? 'txtopen' : ''}`}>
              
              <span className="arrow">➔</span>
              <div>
                  <h3>PARTICIPACIÓN</h3>
              </div>
            </div>
          </div>
            {openSection === 'participacion' && <div className="section-content"><Participacion /></div>}

 {/* //-------------------------------------------------------------------------------------------------------        */}
        <div
          id="premios"
          className={`section ${openSection === 'premios' ? 'open' : ''}`}
          onClick={() => toggleSection('premios')}
        >
          <div className={`section-header ${openSection === 'premios' ? 'txtopen' : ''}`}>
            <span className="arrow">➔</span>
            <div>  
                <h3>SOBRE EL PREMIO</h3>
            </div>
          </div>
        </div>
        {openSection === 'premios' && <div className="section-content"><Premios /></div>}

       
 {/* //-------------------------------------------------------------------------------------------------------        */}

        <div
          id="fechas"
          className={`section ${openSection === 'fechas' ? 'open' : ''}`}
          onClick={() => toggleSection('fechas')}
        >
        <div className={`section-header ${openSection === 'fechas' ? 'txtopen' : ''}`}>
            <span className="arrow">➔</span>
            <div>
              <h3>FECHAS</h3>  
            </div>
          </div>
        </div>
        {openSection === 'fechas' && <div className="section-content"><Fechas /></div>}

 {/* //-------------------------------------------------------------------------------------------------------        */}
        <div
          id="about"
          className={`section ${openSection === 'about' ? 'open' : ''}`}
          onClick={() => toggleSection('about')}
        >
          <div className={`section-header ${openSection === 'about' ? 'txtopen' : ''}`}>
            <span className="arrow">➔</span>
            <div>
              <h3>PREMIOS</h3>
            </div>
          </div>
        </div>
        {openSection === 'about' && <div className="section-content"><About /></div>}

 {/* //-------------------------------------------------------------------------------------------------------        */}
        <div
          id="jurado"
          className={`section ${openSection === 'jurado' ? 'open' : ''}`}
          onClick={() => toggleSection('jurado')}
        >
          <div className={`section-header ${openSection === 'jurado' ? 'txtopen' : ''}`}>
            <span className="arrow">➔</span>
            <div>
              <h3>JURADO</h3>
            </div>
          </div>
        </div>
        {openSection === 'jurado' && <div className="section-content"><Jurado /></div>}

 {/* //-------------------------------------------------------------------------------------------------------        */}
        <div
          id="faqs"
          className={`section ${openSection === 'faqs' ? 'open' : ''}`}
          onClick={() => toggleSection('faqs')}
        >
          <div className={`section-header ${openSection === 'faqs' ? 'txtopen' : ''}`}>
            <span className="arrow">➔</span>
            <div>
               <h3>FAQS</h3>
            </div>
          </div>
        </div>
        {openSection === 'faqs' && <div className="section-content"><Faqs /></div>}
{/* //-------------------------------------------------------------------------------------------------------        */}
      </div>
    </div>
  );
};