import React, { useState, useEffect, useRef } from 'react';
import './FileUpload.css'
import ind from '../../../Assets/ArkDesc/individualAutoria.docx'
import eqp from '../../../Assets/ArkDesc/equipoAutoria.docx'


const FileUpload = ({ label, fileType, onFileUpload, formType }) => {
    
    const [selectedFile, setSelectedFile] = useState(null);
    const fileInputRef = useRef(null);
// console.log(label, fileType, onFileUpload, formType)

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        // Verificar si hay un archivo antes de acceder a sus propiedades
        if (!file) {
            return; // Si no hay archivo seleccionado, salir de la función
        }
        // Comprobar el tamaño del archivo
        if (file.size > 5 * 1024 * 1024) { // Límite de tamaño 5MB
            alert('El archivo no debe superar los 5MB');
            return;
        }
        setSelectedFile(file);
        onFileUpload(e); // Pasar el evento directamente
    };

    // const selectFileDowload = () => {}

    useEffect(() => {
        // Resetear el archivo seleccionado y limpiar el input cuando cambie formType
        setSelectedFile(null);
        if (fileInputRef.current) {
            fileInputRef.current.value = ""; // Resetear el valor del input manualmente
        }
    }, [formType]);

    return (
            <div className="file-upload">
                <p className="form-label">{label} (pdf):</p>
            
            <div style={{display:'flex', }}>
                        <input 
                            ref={fileInputRef} // Asignar la referencia al input
                            type="file" 
                            name={fileType}  // El 'name' se utiliza para identificar el archivo
                            onChange={handleFileChange} 
                            accept=".pdf" 
                            required
                            
                        />
                {label === 'Firma del Participante' && (
                    formType === 'individual' ? (
                        <a
                            href={ind}  // Cambia a la ruta del archivo individual
                            download="firma-participante.pdf"
                            style={{ marginLeft: '10px', textDecoration: 'none' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 1 0V13a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1V9.9a.5.5 0 0 1 1 0V13a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V9.9z" />
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                        </a>
                    ) : formType === 'grupal' ? (
                        <a
                            href={eqp}  // Cambia a la ruta del archivo grupal
                            download="firma-grupal.pdf"
                            style={{ marginLeft: '10px', textDecoration: 'none' }}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-download" viewBox="0 0 16 16">
                                <path d="M.5 9.9a.5.5 0 0 1 1 0V13a1 1 0 0 0 1 1h11a1 1 0 0 0 1-1V9.9a.5.5 0 0 1 1 0V13a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V9.9z" />
                                <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                            </svg>
                        </a>
                    ) : null
                )}
                </div>
                {/* Verificar si selectedFile no es null antes de intentar acceder a sus propiedades */}
                {/* {selectedFile ? <p>Archivo seleccionado: {selectedFile.name}</p> : <p>No se ha seleccionado ningún archivo</p>} */}
            </div>
    );
};

export default FileUpload;
