import { createSlice } from '@reduxjs/toolkit';

const sectionSlice = createSlice({
  name: 'section',
  initialState: {
    openSection: null,
  },
  reducers: {
    setOpenSection: (state, action) => {
      state.openSection = action.payload;
    },
    clearOpenSection: (state) => {
      state.openSection = null;
    },
  },
});

export const { setOpenSection, clearOpenSection } = sectionSlice.actions;
export default sectionSlice.reducer;