import React from 'react'

export const Faqs = () => {
  return (
    <div>
        <div className="premios-section">
            <h4>PREGUNTAS FRECUENTES</h4>
              <p>
                  <strong className='stong-blue'>¿Qué es el Premio Peabody? </strong>
                  <br /><br />
                  Es un concurso nacional de diseño cuyos orígenes se remontan a 2017 cuando se
                  realizó la primera edición del Premio. El Premio Peabody tiene <strong className='stong-black'>como objetivo
                  promover a estudiantes de las carreras de diseño al desarrollo de nuevas</strong>
                  <br /><br />
                  <strong className='stong-blue'>¿El Premio Peabody tiene una temática?</strong>
                  <br /><br />
                  Si, la temática de esta edición es <strong>"Más Allá del Presente: Diseñar el futuro Próximo". </strong> 
                  Vivimos un momento de transformación global que afecta diversas disciplinas, desde
                  la tecnología, con la irrupción de la inteligencia artificial como herramienta productiva,
                  hasta la crisis medioambiental y las nuevas tendencias socioculturales del habitar. En
                  este contexto, <strong> es esencial entender cómo los jóvenes estudiantes, futuros agentes de
                  cambio, están abordando estos desafíos. </strong> 
                  En  esta edición del Premio Peabody, el enfoque invita a los participantes a desarrollar
                  propuestas que respondan a estas cuestiones desde una perspectiva innovadora y
                  reflexiva. La intención es que los proyectos no solo aborden el problema, sino que se
                  materialicen en propuestas concretas y viables. Estos pueden manifestarse de
                  diversas formas, desde productos de diseño y desarrollos tecnológicos, hasta
                  mejoras en procesos productivos con menor impacto ambiental.
                  <br /><br />
                  <strong className='stong-blue'>¿Quiénes pueden participar?</strong>
                  <br /><br />
                  Buscamos la participación de <strong> estudiantes, como así también de graduados (máximo
                    1 año de finalizados los estudios con la totalidad de materias aprobadas) </strong> de diversas
                  disciplinas para enriquecer el concurso con una gama amplia de conocimientos. Si
                  bien el eje central sigue siendo el diseño argentino, alentamos la inclusión de
                  perspectivas de otras áreas en torno al diseño, como ingeniería, arquitectura, y
                  desarrollo tecnológico. Esto asegura una evaluación integral y multidimensional de
                  las propuestas.
                  <br />
                    Podrá participar toda persona <strong>mayor de 18 años, residente en la República Argentina,
                    que acredite ser estudiante o graduado de la carrera de grado de diseño, ingeniería,
                    arquitectura y desarrollo tecnológico,</strong> tanto de universidades públicas como privadas,
                    que cumpla con todos los requisitos previstos en las presentes bases. Podrán
                    inscribirse de manera individual o grupal (en equipos de hasta 5 integrantes). En el
                    caso de que se trate de un equipo, todos los integrantes deberán comprobar su
                    condición de alumno regular, y en el supuesto de ser graduados, deberán presentar
                    título o constancia de título en trámite.
                  <br /><br/>
                  <strong className='stong-blue'>¿Se pueden presentar en grupo? </strong>
                  <br /><br />
                  Sí, en equipos de <strong> hasta 5 integrantes.</strong>  En el caso de que se trate de un equipo, todos
                  los/as integrantes deberán comprobar <strong>su condición de alumno/a regular.</strong>
                  <br /><br/>
                  <strong className='stong-blue'>¿Qué tipos de proyectos puedo presentar?</strong>
                  <br /><br />
                    Podrán presentarse <strong> conceptos e ideas de productos, sistemas, y mejoras de
                    productos existentes donde esté implicado el diseño.</strong>  Podrán también, presentarse
                    nuevos negocios, aplicaciones e interfaces siempre que haya un objeto asociado
                    dentro del proyecto. No se considerarán proyectos que estén en el mercado o en
                    producción.
                  <br /><br />
                  <strong className='stong-blue'>¿Cuál es el contenido de la presentación?</strong>
                  <br /><br />
                      Se deberán presentar láminas <strong> tamaño A3, con un mínimo de 3 y un máximo de 5
                      láminas junto con un video corto de 30 segundos. </strong>  Para más información te
                      recomendamos leer la guía de participación. <a href="www.premiopeabody.com.ar/material">www.premiopeabody.com.ar/material</a>
                  <br /><br />
                  <strong className='stong-blue'>¿Por qué debe guardarse con un pseudónimo el archivo presentado?</strong>
                  <br /><br />
                  Solicitamos que el nombre del archivo presentado sea con un nombre fantasía (pseudónimo) con el <strong> fin de mantener el anonimato de los participantes durante el proceso de evaluación.</strong>
                  <br /><br />
                  <strong className='stong-blue'>¿Hasta cuántos proyectos se pueden presentar?</strong>
                  <br /><br />
                    No hay límite en la cantidad de proyectos a presentar, pero no deben estar repetidos.
                  <br /><br />
                  <strong className='stong-blue'>¿Cómo me inscribo?</strong>
                  <br /><br />
                  La inscripción es online completando el formulario en el sitio web www.premiopeabody.com.ar.
                  Para obtener más información te recomendamos leer la guía de participación <a href="www.premiopeabody.com.ar/material"> www.premiopeabody.com.ar/material </a> o escribirnos a inscripcion@premiopeabody.com.ar
                  <br /><br />
                  <strong className='stong-blue'>¿Qué criterios de evaluación tendrá el jurado?</strong>
                  <br /><br />
                  El jurado evaluará los siguientes aspectos:
             
                        <ul>
                            <li>
                             <strong>Concepto:</strong> Como el concepto aborda la temática propuesta en el concurso y explora
                                sobre los distintos modos de habitar.
                            </li>
                            <li>
                            <strong>Diseño:</strong> Cómo el proyecto combina forma, función y tecnología. Innovación: Grado de originalidad y novedad que propone.
                            </li>
                            <li>
                            <strong>Factibilidad:</strong> Potencial de viabilidad para su desarrollo a futuro. Responsabilidad: Cómo afecta el medio ambiente y cuál es su impacto social y cultural.
                            </li>
                            <li>
                            <strong>Responsabilidad:</strong>  Cómo afecta el medio ambiente y cuál es su impacto social y cultural.
                            </li>
                        </ul>
                        <style>
                          {`
                            ul li::marker {
                              color: #007bff;
                            }
                          `}
                        </style>
                  <br />
                  <strong className='stong-blue'>¿Cúal es el premio?</strong> 
                  <br /><br />
                    <strong>
                      Se seleccionará al menos 3 distinciones de manera horizontal, no se establecerá una
                      escala de premios entre los/as ganadores/as.
                      Cada ganador/a (distinguido/a) recibirá los siguientes premios:
                    </strong>
                          <ul>
                            <li> Un monto de USD 1000 (dólar estadounidense mil).</li>
                            <li> Entrega de Diploma Proyecto Distinguido. </li>
                            <li> Posibilidad de firmar un contrato laboral con Goldmund S.A </li>
                            <li> Un eTermo Peabody</li>
                          </ul>
                    <strong>
                          El jurado seleccionará al menos 5 menciones especiales quienes recibirán como
                          premio un eTermo.
                    </strong>
                  <br/><br/>
                  <strong className='stong-blue'>¿De dónde puede descargar las bases y condiciones?</strong> 
                  <br /><br />
                  Las bases y condiciones se pueden descargar del sitio <strong>www.premiopeabody.com.ar</strong> en la sección “Legales”.

            </p>
        </div>
    </div>
  );
};
