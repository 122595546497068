import React from 'react';
import './Premios.css';

export const Premios = () => {
  return (
    <div>
      

      <div className="premios-section">
        <div>
            <h4>Temática</h4>
        </div>
        <div>
            <strong>MÁS ALLÁ DEL PRESENTE: DISEÑAR EL FUTURO PRÓXIMO</strong> <br />
            <p>
               <strong>Vivimos un momento de transformación global </strong> que afecta diversas disciplinas, desde la tecnología,
                con la irrupción de la inteligencia artificial como herramienta productiva, hasta la crisis
                medioambiental y las nuevas tendencias socioculturales del habitar. En este contexto, <strong> es esencial
                entender cómo los jóvenes estudiantes, futuros agentes de cambio, están abordando estos desafíos.</strong>
            </p>
            <ul> 
              <li>¿Cómo están tratando estos temas las universidades?</li>
              <li>¿Cuáles son los nuevos espacios a habitar?</li>
              <li>¿Qué dinámicas emergen en esta nueva era?</li>
              <li>¿Qué papel juega la tecnología en la redefinición de los espacios habitacionales?</li>
              <li>¿Cómo están cambiando las expectativas de los habitantes respecto a sus espacios de vida en
              la era digital?</li>
              <li>¿Cómo pueden los proyectos habitacionales fomentar la interconexión entre los espacios
              públicos y privados?</li>
            </ul>
            <style>
              {`
                ul li::marker {
                  color: #007bff;
                }
              `}
            </style>
            <p>
                En esta edición del Premio Peabody, el enfoque invita a los participantes a <strong>desarrollar propuestas
                que respondan a estas cuestiones desde una perspectiva innovadora y reflexiva.</strong> La intención es que
                los proyectos no solo aborden el problema, sino que se materialicen en propuestas concretas y
                viables. Estos pueden manifestarse de diversas formas, desde productos de diseño y desarrollos
                tecnológicos hasta mejoras en procesos productivos con menor impacto ambiental.
              <br /><br />
                Es fundamental <strong> que las ideas presentadas no queden en el ámbito de la utopía</strong>, sino que se concreten
                en propuestas tangibles. Este enfoque busca promover soluciones reales y aplicables que puedan
                generar un impacto positivo en el entorno próximo y futuro. <strong> Pordrán presentarse conceptos e ideas
                de diseño de objetos, sistemas, interfaces, servicios, aplicaciones, nuevos negocios, mejoras de
                productos existentes donde esté implicado el diseño.</strong> No se considerarán productos que estén en el
                mercado o en producción.
            </p>
        </div>
      </div>
      <div className="premios-section">
        <div>
            <h4>Manifiesto</h4>
        </div>
        <div>
            <p>
            Debido a nuestra trayectoria, comprendemos el diseño como la mejor manera de pensar los objetos,
            las interfaces, los espacios e incluso los servicios. Somos conscientes de la importancia e impacto
            que tiene en la sociedad. A pesar de que las disciplinas del diseño se han perfeccionado, el mundo
            hoy es más exigente. Se requieren desarrollos responsables, teniendo como ejes la innovación, la
            sustentabilidad y la sociedad. Es imposible no pensar su futuro en convivencia con el entorno y
            centrado en la diversidad de las personas.
            Por todo esto, el Premio Peabody alienta a las nuevas generaciones a explorar sobre nuestra cultura
            y nuevos escenarios de la vida cotidiana, con el objetivo de proponer ideas que jamás hayan sido
            pensadas y conceptos que aún no han sido ideados.
            </p>
        </div>
      </div>
    </div>
  );
};
